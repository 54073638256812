import React, { useEffect, useState } from "react";
import css from './SectionCreatorEco.module.css';
import { Carousel } from 'react-responsive-carousel';
import leftArrow from '../../../assets/photos/next-arrow.png';
import rightArrow from '../../../assets/photos/prev-arrow.png';

const SectionCreatorEco = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchData = () => {
      const suggestionList = document.querySelector("upcontent-suggestion-list");
      if (suggestionList) {
        const suggestions = suggestionList.querySelectorAll("upcontent-suggestion");
        const extractedItems = Array.from(suggestions).map((suggestion, index) => {
          const link = suggestion.querySelector("a");
          const img = suggestion.querySelector("img");
          const title = suggestion.querySelector("h3");
          const date = suggestion.querySelector(".uc-gallery--date"); // Adjust class if needed
          const text = suggestion.querySelector(".uc-gallery--summary");
          const label = suggestion.querySelector(".uc-gallery--source");
          return {
            id: index,
            image: img ? img.src : "",
            title: title ? title.textContent : "",
            url: link ? link.href : "#",
            date: date ? date.textContent : "",  // Extract date
            text: text ? text.textContent : "",
            label: label ? label.textContent : ""
          };
        });

        if (extractedItems.length > 0) {
          setItems(extractedItems);
          setIsLoading(false);
        }
      }
    };

    // Wait 10 seconds before extracting content
    const timer = setTimeout(fetchData, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = index => {
    setCurrentIndex(index);
  };

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.leftArrow}
      aria-label={label}
      disabled={!hasPrev}
    >
      <img src={leftArrow} alt="Previous" className={css.arrow} />
    </button>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.rightArrow}
      aria-label={label}
      disabled={!hasNext}
    >
      <img src={rightArrow} alt="Next" className={css.arrow} />
    </button>
  );

  return (
    <div className={css.creatorEcoWrap}>
      <div className={css.container}>
        <div className={css.creatorEcoContainer}>
          <div className={css.creatorEcoTopCont}>
            <h2 className={css.creatorEcoHeading}>
              What’s Happening in the<br /> Creator Economy
            </h2>
          </div>
          <div>
            <div style={{ display: "none" }}>
              <upcontent-suggestion-list
                gallery="8a159f80-3636-4224-9830-d84e1c5d13ca"
                className="uc-theme--39ac67b7-0a20-4c58-a0a7-3c72d29977e2"
              ></upcontent-suggestion-list>
            </div>
            {isLoading ? (
              <p>Loading content...</p>
            ) : items.length > 0 ? (
              <Carousel
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                showArrows={true}
                showIndicators={true}
                infiniteLoop={true}
                swipeable={true}
                transitionTime={2000}
                interval={5000}
                onChange={handleChange}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
                centerMode={true}
                centerSlidePercentage={33.33}
                className={css.finalSlider}
              >
                {items.map((item) => (
                  <div className={css.creatorEcoSlider} key={item.id}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer" className={css.creatorSliderCont}>
                      <div className={css.creatorSlideImg}>
                        <img src={item.image} alt={item.title} className="w-full h-48 object-cover rounded-md" />
                      </div>
                      <div className={css.creatorAuthWrap}>
                        <div className={css.creatorSlideContWrap}>
                          <h3 className={css.creatorSlideHead}>{item.title}</h3>
                          <div className={css.creatorEcoLabelWrap}>
                            <p className={css.creatorSlideDate}>{item.date}</p>
                            <p className={css.creatorSlideLabel}>{item.label}</p>
                          </div>
                        </div>
                        {item.text}
                      </div>
                    </a>
                  </div>
                ))}
              </Carousel>
            ) : (
              <p>No content available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionCreatorEco;
